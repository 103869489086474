.react-datepicker-popper {
  z-index: 1031;
}

.react-datepicker-wrapper {
  width: 100%;
}
.react-datepicker__input-container {
  position: relative;
  display: flex;
  flex-wrap: nowrap;
  align-items: stretch;
  width: 100%;
}
.react-datepicker__input-container .input-group-text, .react-datepicker__input-container .form-control {
  padding: 0.1875rem 0.75rem;
  font-size: 0.875rem;
  border-radius: .25rem;
}
.react-datepicker__input-container :not(:last-child):not(.dropdown-toggle):not(.dropdown-menu):not(.form-floating),
.react-datepicker__input-container .dropdown-toggle:nth-last-child(n+3),
.react-datepicker__input-container .form-floating:not(:last-child) > .form-control,
.react-datepicker__input-container .form-floating:not(:last-child) > .form-select {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.react-datepicker__input-container :not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(.invalid-feedback) {
  margin-left: calc(1px * -1);
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.react-datepicker .react-datepicker__month-container {
  width: 100%;
}
.react-datepicker .react-datepicker__year--container {
  width: 100%;
  background-color: var(--falcon-white);
}
.react-datepicker .react-datepicker__year-wrapper {
  max-width: 13rem;
}
.react-datepicker .react-datepicker__day {
  color: #27bcfd !important;
}
.react-datepicker .react-datepicker__day--weekend {
  color: var(--danger) !important;
}
