.table-column-width-30 {
  width: 30%;
}

.table-column-width-25 {
  width: 25%;
}

.table-column-width-20 {
  width: 20%;
}

.table-column-width-15 {
  width: 15%;
}