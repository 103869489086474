.tablePartGrid {
  /*display: grid !important;*/
  /*width: calc(100vw - 150px) !important;*/
  /*aamax-height: -webkit-fill-available;*/
  top: 0;
  bottom: 0;
  height: 100%;
  overflow: auto;
  grid-template-rows: [header] auto [body] 1fr [footer] auto;
  background: white;
  /*agrid-auto-columns: 100%;*/
}

.grid_item {
  border: 1px gray dotted ;
  margin: 5px;
}

.tablePartGrid > :first-child {
  top: 0;
  z-index: 1;
  position: sticky;
}

.tablePartGrid > .header {
  grid-row: header;
  width: 100%;
}

.tablePartGrid > .body {
  grid-row: body;
  /*overflow-y: auto;*/
  width: 100%;
  z-index: 0;
}

.tablePartGrid > .footer {
  grid-row: footer;
  width: 100%;
}

