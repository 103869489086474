@import url('https://fonts.googleapis.com/css?family=Roboto:400,700');
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@500&display=swap');

*{
  box-sizing: border-box;
}
body {
  margin: 0;
  padding: 0;
  font-family: Roboto, sans-serif;
  background-color: #dadada;
  min-width: 320px;
  color: rgba(0, 0, 0, 0.87);
  /*line-height: 1.4285em;*/
  font-size: 14px;
  /*height: 100%;*/
  --negative-color: #ff0000;
  overflow: hidden;

  /*------Buttons--------------------------------------------------*/
  --backgroundButton: #007bff;
  --colorTextButton: #fff;
  --hoverButton: #0069d9;

  /*------Table-----------------------------------------------------*/
  --backgroundActiveRow: linear-gradient(0deg,rgba(255,255,255,0.7),rgba(255,255,255,0.7)),#008F21;
  --colorTextActiveRow: #456f97;
  --backgroundTableHeader: linear-gradient(0deg,#d0e5fa,#dde9f8,#dfeefd,#d2def8);
  --colorTextTableHeader: #4d535b;
  --tableBorder: #dee2e6;
  /* --- OTHER  -------*/
  --borderTab: #dee2e6;
}

#root{
  font-size: 14px;
}

textarea {
  overflow-x: hidden;
}

button:focus,
a:focus, a:active,
button::-moz-focus-inner,
input[type="reset"]::-moz-focus-inner,
input[type="button"]::-moz-focus-inner,
input[type="submit"]::-moz-focus-inner,
select::-moz-focus-inner,
input[type="file"] > input[type="button"]::-moz-focus-inner {
  outline: none !important;
}

/* Works without this styles below, but anything can be happened

/*.app-content {*/
  /*min-height: 100vh;*/
  /*!*padding: 0.5em 0;*!*/
  /*display: flex;*/
  /*flex-direction: column;*/
  /*justify-items: flex-start;*/
  /*align-items: stretch;*/
/*}*/

/*.app-content > * {*/
  /*flex: 0 0;*/
/*}*/

/*.app-content > main {*/
  /*flex: 1 100%;*/
  /*justify-self: flex-start;*/
/*}*/

/*.app-content > *:first-child {*/
  /*justify-self: flex-end;*/
/*}*/

/*.print-area {*/
/*  display: none;*/
/*}*/

::-webkit-scrollbar { width: 8px; height: 8px;}
/*::-webkit-scrollbar-button {  height: 8px; background-color: #afb1b6; }*/
/*::-webkit-scrollbar-track {  background-color: #d2def6;}*/
/*::-webkit-scrollbar-track-piece { background-color: #ffffff;}*/
::-webkit-scrollbar-thumb { height: 50px; background-color: #b8c6e4; border-radius: 0.3em;}
::-webkit-scrollbar-corner { background-color: #d2d4db;}
::-webkit-resizer { background-color: #d2d4db;}


@media print {
  body > * {
    display: none;
    visibility: hidden;
  }

  /*.print-area {*/
  /*  display: block;*/
  /*  overflow: hidden;*/
  /*  visibility: visible;*/
  /*  width: fit-content;*/
  /*  color: black;*/
  /*}*/

  /*.print-area tr {*/
  /*  page-break-inside: avoid;*/
  /*}*/

  td.ActiveCell {
    background: none !important;
   }

  td.FixedToTop, td.FixedToLeft {
    background: none !important;
    box-shadow: none !important;
    position: initial !important;
   }
}

.gap-1{
  gap: .25rem!important;
}

.gap-2{
  gap: .5rem!important;
}

.gap-3{
  gap: 1rem!important;
}

.gap-4{
  gap: 1.5rem!important;
}

.gap-5{
  gap: 3rem!important;
}
.form-group {
  position: relative;
  margin: 1rem 0 0 0;
}

.form-group > .form-label {
  position: absolute;
  left: 0.2rem;
  top: 0.2rem;
  right: 0.2rem;
  padding: 0 0.5rem;
  cursor: text;
  transition: top 200ms ease-in, left 200ms ease-in, font-size 200ms ease-in;
  border-radius: 25px;
  max-width: 95%;
  width: -moz-fit-content;
  z-index: 4;
  width: fit-content;
}

.form-group.floated > .form-label {
  top: -0.7rem;
  font-size: 0.75rem;
  left: 0.2rem;
  max-width: 90%;
  width: fit-content;
  border-radius: 25px;
  z-index: 5;
  background: linear-gradient(0deg, var(--falcon-white) 34%, rgb(247 247 247) 35%);
}
.form-group.alwaysFloated > .form-label {
  top: -0.7rem;
  font-size: 0.75rem;
  left: 1.2rem;
  max-width: 90%;
  width: fit-content;
  border-radius: 25px;
  z-index: 5;
  background: linear-gradient(0deg, var(--falcon-white) 34%, #ffffff 35%);
}

.form-group.floated > .control-disabled {
  background: linear-gradient(0deg, #e9ecef 34%, #f7f7f7 35%);
}

.form-control:focus {
  box-shadow: none;
}
