:root{
  --falcon-gray-200: #edf2f9;
  --falcon-gray-300-rgb: 216, 226, 239;
  --falcon-gray-700: #5e6e82;
  --falcon-box-shadow-sm: var(--falcon-box-shadow-sm);
}

.border-300 {
  --falcon-border-opacity: 1;
  border-color: rgba(var(--falcon-gray-300-rgb), var(--falcon-border-opacity)) !important;
}

.fs-1 {
  font-size: 1.2rem !important;
}

.icon-group .icon-item:not(:last-child) {
  margin-right: 0.5rem;
}

.icon-item {
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--falcon-gray-700);
  transition: all 0.2s ease-in-out;
  height: 2.5rem;
  width: 2.5rem;
  border: 0;
  font-size: 0.875rem;
  box-shadow: var(--falcon-box-shadow-sm);
}
.icon-item:hover, .icon-item:focus {
  background-color: var(--falcon-gray-200) !important;
}
.icon-item.icon-item-sm {
  height: 1.875rem;
  width: 1.875rem;
}
.icon-item.icon-item-lg {
  height: 2.75rem;
  width: 2.75rem;
}
.icon-item.icon-item-xl {
  height: 3.125rem;
  width: 3.125rem;
  font-size: 1.2rem;
}
.dropdown-menu{
  z-index: 1031!important;
}