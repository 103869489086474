.variant_panel {
    padding: 0;
    margin: 0;
    display: flex;
    overflow-y: hidden;
    overflow-x: auto;
}

.variant {
    display: flex;
    flex-direction: row;
    max-height: 90px;

}


.variant__report {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    border: 1px solid rgb(72, 141, 228);
    border-radius: 0.3rem;
    padding: 10px 5px 5px 0;
    max-width: 200px;
    height: 80px;
    margin: 5px 5px 5px;
    position: relative;
}

.variant__report-gen {
    display: flex;
    flex-direction: row;
    border: 1px solid rgb(72, 141, 228);
    border-radius: 0.3rem;
    width: 180px;
    height: 80px;
    margin: 5px 5px 5px 0;
    align-items: center;
}

.variant__rep-gen-img {
    margin: 10px 5px;
}

.variant__rep-set-img {
    padding: 10px 10px 0 10px;
}

.variant__button {
    border: none;
    background: none;
}

.variant__report-set {
    display: flex;
    flex-direction: column;
    border: 1px solid rgb(72, 141, 228);
    border-radius: 0.3rem;
    width: 110px;
    height: 80px;
    margin: 5px 5px 5px;
    align-items: center;
}

.vl {
    padding-left: 10px;
}

.variant__text-set {
    color: rgb(72, 141, 228);
    font-family: Roboto, sans-serif;
    font-size: 0.8em;
    padding: 0 5px 0;
}

.variant__text-set:hover {
    font-weight: 900;
}

.variant__text-save {
    color: rgb(72, 141, 228);
    font-family: Roboto, serif;
    font-size: 0.8em;
    padding: 0 5px 0 350px;
    margin-top: -15px;
}

.variant__text {
    color: rgb(72, 141, 228);
    font-family: Roboto, sans-serif;
    font-size: 0.8em;
    text-decoration: underline;
    text-align: justify-all;
    border: none;
    background: none;
    line-height: 1;
    overflow: hidden;
    height: 60px;
    text-overflow: clip;
    top: 0;
}

.variant__text:hover {
    font-weight: 900;
}

.text-gen {
    line-height: 1.5;
}

.text-set {
    padding: 5px 5px 0;
}

.variant__dell-button {
    border: 1px solid rgb(162, 180, 232);
    box-shadow: rgba(0, 0, 0, 0.16) 0 0 20px;
    color: rgb(94, 147, 208);
    border-radius: 0.3rem;
    outline: none;
    padding: 3px 3px 3px 3px;
    height: 30px;
    display: inline-flex;
    align-items: center;
    position: absolute;
    bottom: 3px;
    right: 3px;
    margin-left: 1px;
}

.variant__dell-button:hover {
    box-shadow: -5px -5px 5px -5px rgb(246, 191, 155) inset;
}
